<template>
	<div class="box">
		<div class="row">
			<div class="col-12">
				<h2>{{ $t('global.web_content') }}</h2>

				<LoadingSpinner v-if="loading" />
				<template v-else>
					<!-- <b-form-checkbox class="mt-1 mb-1" v-model="web" switch>
						{{ $t('global.afficher_web') }}
					</b-form-checkbox> -->

					<div v-for="type in contents" :key="type.contenttype_id" class="mb-3">
						<WebContentEdit :locales="locales" :contents="type.contents" :type="type" :update="updateContent"/>
					</div>

			        <div class="col-12 mt-2 text-center">
			        	<button class="btn btn-primary" @click="save">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></button>
			        </div>
				</template>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import TranslationContent from '@/mixins/TranslationContent'

	export default {
		name: 'WebContent',
		mixins: [TranslationContent],
		props: ['id', 'type'],
		data () {
			return {
				contents: [],
				locales: [],
				loading: false,
				processing: false,
				web: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading = true
				this.locales = this.getLocales()
				const contents = await this.getContentByModel(this.id, this.type)
				// this.web = contents.web
				this.contents = contents.contents.map(content => {
					let contents = {}
					// let web = false
					// pour chaque langue je vais récupérer le contenu déjà enregistré s'il existe
					for(let i in this.locales) {
						let text = ''
						if(content.contents.length > 0) {
							let content_lang = content.contents.find(c => c.contentweb_lang == this.locales[i].code)

							if(content_lang) {
								text = content_lang.contentweb_content
								// web = content_lang.contentweb_web
							}
						}
						contents[this.locales[i].code] = text
					}
					return {...content, contents}//, web}
				})
				this.loading = false
			},

			async save() {
				this.processing = true
				await this.saveContentByModel(this.id, this.type, this.contents, this.web)
				this.processing = false
				this.successToast("toast.info_save_succes")
			},

			updateContent(type_id, lang, content) {
				const index = this.contents.findIndex(c => c.contenttype_id == type_id)
				this.contents[index].contents[lang] = content
			}
		},

		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            WebContentEdit : () => import('@/components/WebContentEdit')
		}
	}

</script>