import Constants from 'Constants'
import Common from '@/assets/js/common.js'

const TranslationContent = {
    methods: {
        getTranslations: async function(codes = []) {
            let url = Constants.ALL_TRANSLATIONS + "?licence_key="+Constants.USER_LICENCE_KEY
            if(codes !== null) {
            	url += "&codes=" + codes.join(',')
            }

			const result = await this.$request.request_get_api("TranslationContent::getTranslations", url)
			.catch(error => {
				console.error("TranslationContent::getTranslations => ERROR", error)
				return null
			})

			return result ? result.retour : null
        },

		getTranslationsByCode: async function(code) {
            const url =  this.constructRoute(Constants.TRANSLATIONS_BY_CODE, { code }) +'?licence_key='+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("TranslationContent::getTranslationsByCode", url)
			.catch(error => {
				console.error("TranslationContent::getTranslationsByCode => ERROR", error)
				return null
			})

			return result ? result.retour : null
		},

		saveTranslationByCodeAndLang: async function(code, lang, params) {
            const url =  this.constructRoute(Constants.TRANSLATIONS_BY_CODE, { code }) +'?licence_key='+Constants.USER_LICENCE_KEY + "&lang=" + lang

            const response = await this.$request.request_post_api("TranslationContent::saveTranslationByCodeAndLang", url, params, false)
            .catch(e => {
                return false
            })

            if(response.code_retour === 0){
                return true
            }
		},

		saveContentType: async function(contents) {
            const url = Constants.WEB_CONTENT_TYPE + "?licence_key="+Constants.USER_LICENCE_KEY

            const response = await this.$request.request_post_api("TranslationContent::saveContentType", url, {contents}, false)
            .catch(e => {
                return false
            })

            if(response.code_retour === 0){
                return true
            }
		},

		getAllContentType: async function() {
            const url = Constants.WEB_CONTENT_TYPE + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("TranslationContent::getContentType", url)
			.catch(error => {
				console.error("TranslationContent::getContentType => ERROR", error)
				return null
			})

			return result ? result.retour : null
		},

		getContentByModel: async function(model_id, model_type) {
            const url =  this.constructRoute(Constants.WEB_CONTENT_MODEL, { model_id, model_type }) +'?licence_key='+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("TranslationContent::getContentByModel", url)
			.catch(error => {
				console.error("TranslationContent::getContentByModel => ERROR", error)
				return null
			})

			return result ? result.retour : null
		},

		saveContentByModel: async function(model_id, model_type, contents, web) {
            const url =  this.constructRoute(Constants.WEB_CONTENT_MODEL, { model_id, model_type }) +'?licence_key='+Constants.USER_LICENCE_KEY

            const response = await this.$request.request_post_api("TranslationContent::saveContentByModel", url, {contents, web}, false)
			.catch(error => {
				console.error("TranslationContent::saveContentByModel => ERROR", error)
				return null
			})

            if(response.code_retour === 0){
                return true
            }
		}
    }
}

export default TranslationContent